import { ThemeProvider } from "@mui/material";
import "./App.css";
import { Home } from "./components/Home";
import { theme } from "./theme";
import { Route, Routes } from "react-router-dom";

function App() {
     return (
               <Routes>
                    <Route path="/:gender/:discomfort/:notice/:contact/:email/:providerid" element={
                         <Home />
                    } />
                  
               </Routes>
     );
}

export default App;
