import React, { useState } from "react";

import { useParams } from "react-router-dom";
import powered from "../../assets/images/powered.png";
import { CircularProgress, Chip } from "@mui/material";

import { useEffect } from "react";
import axios from "axios";


export const Home = () => {
     const defaultApiKey = "9J/RwaHFaZAebJvXz1/2";

     const baseURL = process.env.REACT_APP_REST_BASE_URL;


     const [show, setShow] = useState(true)
     const [newId, setNewId] = useState("")
     const [load, setLoad] = useState(false)
     const [err, setErr] = useState(false)




     var { gender, discomfort, notice, contact, email, providerid } = useParams();


     useEffect(()=>{
          
          
          const getProvider = async() =>{
               const result = await axios.get(`${process.env.REACT_APP_BASE_URL}rest/providers?filterBy[id]=${providerid}&page=1`, {
                    headers: {
                         'Content-Type': 'applications/json',
                         'Authorization': `Api-Key ${defaultApiKey}`
                    }
               });

               console.log(result,'jjjs')
          }

          getProvider()
     })


   



     const handleSuccess = (url) => {
          console.log(url)

          window.open(url, '_self');

     };

     const handleFetchConsultation = async (url, data) => {
          return await fetch(url, {
               method: "POST",
               headers: {
                    "Content-Type": "application/json",
                    authorization: `Api-Key ${defaultApiKey}`,
               },
               body: JSON.stringify(data),
          });
     };

     const handleSubmit = () => {
          setLoad(true)

          const validateFunction = async (id) => {


               const result = await axios.get(`${process.env.REACT_APP_BASE_URL}rest/enrollees?filterBy[providerId]=${providerid}&filterBy[hmoId]=${id.toUpperCase()}&page=1`, {
                    headers: {
                         'Content-Type': 'applications/json',
                         'Authorization': `Api-Key ${defaultApiKey}`
                    }
               });




               if (result.data.data.length === 1) {
                    let first = result.data.data[0].firstName
                    let last = result.data.data[0].lastName
                    let id = result.data.data[0].hmoId
                    let hmo_email = result.data.data[0].email
                    let hmo_phone = result.data.data[0].phone


                    const data = {
                         consultationOwner: "Myself",
                         symptoms: [{
                              name: 'N/A',
                         }],
                         discomfortLevel: discomfort,
                         description: 'N/A',
                         firstNotice: notice,
                         type: "instant",
                         time: "",
                         status: "pending",
                         providerId: providerid,
                         contactMedium: contact,
                         patientData: {
                              email:hmo_email? hmo_email: email,
                              firstName: first,
                              lastName: last,
                              phoneNumber: hmo_phone ? hmo_phone : '08023415933',
                              gender: gender,
                              hmoId: id,
                              image: "https://dq1z5gvyi71s7.cloudfront.net/heala-file-2023-03-15-12-45-156933.png",
                              height: "175",
                              weight: "80",
                              bloodGroup: "A+",
                              genotype: "AA",
                         },
                         timeOffset: "",
                    };

                    handleFetchConsultation(baseURL, data)
                    .then((res) => {

                         return res.text();
                    })
                    .then((res) => {
                         const responseData = JSON.parse(res);
                         if (responseData.message.length > 0){
                              setErr(responseData.message)
                              setLoad(false)
                             }
                         const consultationURL = responseData.data.url;
                         handleSuccess(consultationURL);
                    }).catch(err => {
                         setErr(err.response.data.message)
                         setErr(err.response.message)

                         setLoad(false);

                    }
                    );
                   

               } else {
                    setShow(true)
                    setLoad(false)
                    setNewId('')
                    setErr('Invalid HMO ID / Policy ID')

               }

          }

          validateFunction(newId)

     }



     return (
          <>
               {show ? (
                    <div className="homeCon">
                         <img className="logo" src='https://dq1z5gvyi71s7.cloudfront.net/heala-file-2023-03-13-11-55-562153.png'/>

                         <div className="cont">
                              <div className="erroCon">
                                   {err.length > 0 ? <p className="errred">{err}</p> : <p className='infomsg'>HMO ID / Policy ID</p>}

                                   <input placeholder="Enter HMO ID / Policy ID" value={newId} onChange={(e) => setNewId(e.target.value)} />
                                   <button onClick={handleSubmit}>
                                        {load ? (
                                             <CircularProgress style={{ width: 20, height: 20, color: '#fff' }} />
                                        ) : (
                                             "Submit"
                                        )}
                                   </button>
                              </div>
                         </div>
                         <div className='j'>
                              <img src={powered} alt="" />
                         </div>
                    </div>
               ) : ''}
          </>
     );
};

